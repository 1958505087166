<template>
  <div class="h-100">
    <ul class="nav-menu h-100">
      <li class=" h-100">
        <div class="h-100 d-flex" style="align-items: center;">
          <div
            class="h-100"
            @click="selectedMenuItem(text, '')"
            @mouseover="hoveredSolution = true"
            @mouseout="hoveredSolution = false"
          >
            <div
              class="h-50 d-flex"
              style="text-align: start; padding-left: 10px; align-items: end; justify-content: start;"
            >
              <img
                :src="require('@/assets/Icons/' + icon + '.svg')"
                :alt="text"
              />
            </div>
            <div class="h-50">
              <span class="title pt-2 p4-3" style="box-shadow: none"
                >{{ text }}
                <p class="m-0 pl-2">
                  <i
                    v-if="dropdown"
                    class="fas fa-angle-down"
                    :class="hoveredSolution ? 'fa-angle-up' : 'fa-angle-down'"
                    style="color: #313846"
                  ></i></p
              ></span>
            </div>
          </div>
        </div>
        <ul class="mt-2" v-if="dropdown">
          <li
            v-for="(menuList, index) in dropdownList"
            :key="index"
            :class="{ menuActive: nav === 'solution' }"
          >
            <span
              @click="selectChildItem(menuList.name, '')"
              class="d-flex"
              :title="menuList.name"
              style="justify-content: space-between;"
              >{{ menuList.name }}
              <i
                class="fas fa-angle-right"
                v-if="menuList.subMenu.length !== 0"
              ></i
            ></span>
            <ul v-if="menuList.subMenu.length !== 0">
              <li v-for="(subMenu, i) in menuList.subMenu" :key="i">
                <span @click="selectChildItem(menuList.name, subMenu.name)" :title="subMenu.name">{{
                  subMenu.name
                }}</span>
              </li>
            </ul>
          </li>
        </ul>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  props: {
    dropdown: {
      type: Boolean,
      default: false,
    },
    text: {
      type: String,
      default: "Default",
    },
    icon: {
      type: String,
      default: "demo",
    },
    dropdownList: {
      type: Array,
      default: () => [],
    },
    nav: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      hoveredSolution: false,
      dropdown1: this.dropdown,
    };
  },
  created() {
    this.dropdown1 = this.dropdown;
  },
  beforeUpdate() {
    this.dropdown1 = this.dropdown;
  },
  methods: {
    selectChildItem(name, subName) {
      var data = this.dropdownList.find((obj) => obj.name === name).subMenu
        .length;
      var dropdownBoolean = true;
      if (data === 0) {
        dropdownBoolean = false;
      }
      this.$emit("SelectedMenu", {
        title: name,
        subMenu: subName,
        dropdown: dropdownBoolean,
      });
    },
    selectedMenuItem(name, subName) {
      if (this.dropdown) {
        return;
      } else {
        if (subName === "") {
          dropdownBoolean = false;
        } else {
          var data = this.dropdownList.find((obj) => obj.name === name).subMenu
            .length;
          var dropdownBoolean = true;
          if (data === 0) {
            dropdownBoolean = false;
          }
        }

        this.$emit("SelectedMenu", {
          title: name,
          subMenu: subName,
          dropdown: dropdownBoolean,
        });
      }
    },
    selectMenu(e) {
      if (e === "solution") {
        this.hoveredSolution = true;
        this.dropdown1 = true;
      } else {
        this.hoveredSolution = false;
        this.dropdown1 = false;
      }
    },
  },
};
</script>

<style scoped>
.fa-angle-down:before,
.fa-angle-up:before {
  color: #050505;
}
.menuActive:nth-child(2) {
  background-color: #ddd;
  font-family: ProximaNovaBold;
  background-color: #eff5ff;
}
* {
  margin: 0;
  border: 0;
  padding: 0;
  font-size: 100%;
}
body {
  padding: 2em;
  font: 14px Arial, sans-serif;
  background: #fff;
  color: #333;
}

span {
  color: #428bca;
  text-decoration: none;
}
span:hover,
span:focus {
  color: #999;
  text-decoration: none;
  outline: 0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0 0 20px;
  font-size: 30px;
  line-height: 1.1;
}
h2 {
  font-size: 28px;
}
h3 {
  border-bottom: 1px solid #ddd;
  padding: 0 0 10px;
  font-size: 24px;
}
h4 {
  font-size: 20px;
}
h5 {
  font-size: 18px;
}
h6 {
  font-size: 16px;
}

p,
ul,
ol,
table {
  margin: 0 0 20px;
}
ul,
ol {
  margin: 0 0 20px 25px;
}
ul ul,
ul ol,
ol ol,
ol ul {
  margin-bottom: 0;
}
table table {
  margin-bottom: 0;
}
hr {
  margin: 0 0 19px;
  border: 0 none;
  border-top: 1px solid #ddd;
  height: 0;
  background: transparent;
  color: transparent;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
nav {
  margin: 0 0 20px;
}
nav:before,
nav:after {
  content: " ";
  display: table;
}
nav:after {
  clear: both;
}
/* nav */
.nav-menu,
.nav-menu ul,
.nav-menu li,
.nav-menu span {
  margin: 0;
  padding: 0;
  line-height: normal;
  list-style: none;
  display: block;
  position: relative;
}
.nav-menu ul {
  opacity: 0;
  position: absolute;
  top: 78%;
  left: -9999px;
  z-index: 999;
  -webkit-transition: opacity 0.3s;
  transition: opacity 0.3s;
}
.nav-menu li:hover > ul {
  left: 0;
  opacity: 1;
  z-index: 1000;
}
.nav-menu ul li:hover > ul {
  top: 0;
  left: 100%;
}
.nav-menu li {
  cursor: pointer;
  white-space: nowrap;
  text-align: start;
  align-items: center;
}
.nav-menu ul li {
  float: none;
}
.nav-menu .title {
  align-items: center;
  display: flex;
}
.nav-menu img {
  height: 22px;
}
/* sub width */
.nav-menu ul {
  min-width: 100%;
  -webkit-box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2);
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2);
}

/* root */
.nav-menu span {
  padding: 0 10px;
  color: #050505;
  font-weight: normal;
  font-size: 16px;
  text-decoration: none;
  font-family: ProximaNovaRegular;
}

/* root: hover/persistence */
.nav-menu div:hover,
.nav-menu div:focus,
.nav-menu li:hover div {
  background: #fff;
  color: #050505;
  font-family: ProximaNovaBold;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
}
.nav-menu span:hover,
.nav-menu span:focus,
.nav-menu li:hover span {
  background: #fff;
  color: #050505;
  font-family: ProximaNovaBold;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
}

/* 2 */
.nav-menu li li span,
.nav-menu li:hover li span {
  padding: 13px 10px;
  background: #fff;
  color: #050505;
  font-size: medium;
  line-height: normal;
  font-family: ProximaNovaRegular;
}

/* 2: hover/persistence */
.nav-menu li:hover li span:hover,
.nav-menu li:hover li span:focus,
.nav-menu li:hover li:hover span {
  background: #eff5ff;
  color: #050505;
  font-family: ProximaNovaBold;
}

/* 3 */
.nav-menu li:hover li:hover li span {
  background: #fff;
  color: #050505;
  font-family: ProximaNovaRegular;
}

/* 3: hover/persistence */
.nav-menu li:hover li:hover li span:hover,
.nav-menu li:hover li:hover li span:focus,
.nav-menu li:hover li:hover li:hover span {
  background: #eff5ff;
  color: #050505;
  font-family: ProximaNovaBold;
}

/* 4 */
.nav-menu li:hover li:hover li:hover li span {
  background: #eff5ff;
  color: #050505;
  font-family: ProximaNovaBold;
}

/* 4: hover */
.nav-menu li:hover li:hover li:hover li span:hover,
.nav-menu li:hover li:hover li:hover li span:focus {
  background: #eff5ff;
  color: #050505;
  font-family: ProximaNovaBold;
}
</style>
