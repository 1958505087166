<template>
  <div class="header-intro-main">
    <div
      class="header-intro"
      :class="{
        'z-index-header': index === 'nav',
        'z-index-navbar': index !== 'nav',
      }"
    >
      <span class="d-flex" :style="index !== 'nav' ? 'margin-top: 71px': 'margin-top: 1px'">
        <a
          href="javascript:;"
          @click="navigate('finish')"
          class="intro-link ProximaNovaRegular"
          >Skip</a
        >
      </span>
      <img
        src="../../assets/group-5.svg"
        alt
        class="header-intro-arrow"
        v-if="index === 'nav'"
      />
      <img
        src="../../assets/group-6.svg"
        alt
        class="header-intro-arrow-6"
        v-if="index === 'solution'"
      />
      <img
        src="../../assets/group-7.svg"
        alt
        class="header-intro-arrow-7"
        v-if="index === 'Data connection'"
      />
      <img
        src="../../assets/group-7.svg"
        alt
        class="header-intro-arrow-8"
        v-if="index === 'savedplan'"
      />
      <img
        src="../../assets/group-8.svg"
        alt
        class="header-intro-arrow-9"
        v-if="index === 'globalsearch'"
      />
      <img
        src="../../assets/group-8.svg"
        alt
        class="header-intro-arrow-10"
        v-if="index === 'notifications'"
      />
      <img
        src="../../assets/group-8.svg"
        alt
        class="header-intro-arrow-11"
        v-if="index === 'profile'"
      />
      <img
        src="../../assets/group-6.svg"
        alt
        class="header-intro-arrow-12"
        v-if="index === 'breadcrumb'"
      />
      <span
        class="intro-navi-menu intro-navi-menu-5 ProximaNovaRegular"
        v-if="index === 'nav'"
      >
        Navigation Menu
        <p class="intro-desc ProximaNovaRegular">
          Quick access to all the modules, menus and more
        </p>
        <a
          href="javascript:;"
          @click="navigate('solution')"
          class="intro-link ProximaNovaRegular"
          >I understood!</a
        >
      </span>
      <span
        class="intro-navi-menu intro-navi-menu-6 ProximaNovaRegular"
        v-if="index === 'solution'"
      >
        Solutions
        <p class="intro-desc ProximaNovaRegular">
          To select your licensed products
        </p>
        <a
          href="javascript:;"
          @click="navigate('Data connection')"
          class="intro-link ProximaNovaRegular"
          >I understood!</a
        >
      </span>
      <span
        class="intro-navi-menu intro-navi-menu-7 ProximaNovaRegular"
        v-if="index === 'Data connection'"
      >
        Data Connection
        <p class="intro-desc ProximaNovaRegular">
          Of all the channels using an excel sheet to create media ROI at
          product level
        </p>
        <a
          href="javascript:;"
          @click="navigate('savedplan')"
          class="intro-link ProximaNovaRegular"
          >I understood!</a
        >
      </span>
      <span
        class="intro-navi-menu intro-navi-menu-8 ProximaNovaRegular"
        v-if="index === 'savedplan'"
      >
        Saved plans
        <p class="intro-desc ProximaNovaRegular">
          View, find & organize all the saved and shared plans
        </p>
        <a
          href="javascript:;"
          @click="navigate('globalsearch')"
          class="intro-link ProximaNovaRegular"
          >I understood!</a
        >
      </span>
      <span
        class="intro-navi-menu intro-navi-menu-9 ProximaNovaRegular"
        v-if="index === 'globalsearch'"
      >
        Global search
        <p class="intro-desc ProximaNovaRegular">
          Search for your products, plans, data and much more.
        </p>
        <a
          href="javascript:;"
          @click="navigate('notifications')"
          class="intro-link ProximaNovaRegular"
          >I understood!</a
        >
      </span>
      <span
        class="intro-navi-menu intro-navi-menu-10 ProximaNovaRegular"
        v-if="index === 'notifications'"
      >
        Notifications
        <p class="intro-desc ProximaNovaRegular">
          Find all your notifications here to stay updated
        </p>
        <a
          href="javascript:;"
          @click="navigate('profile')"
          class="intro-link ProximaNovaRegular"
          >I understood!</a
        >
      </span>
      <span
        class="intro-navi-menu intro-navi-menu-11 ProximaNovaRegular"
        v-if="index === 'profile'"
      >
        Profile
        <p class="intro-desc ProximaNovaRegular">
          To view, manage and update profile details
        </p>
        <a
          href="javascript:;"
          @click="navigate('breadcrumb')"
          class="intro-link ProximaNovaRegular"
          >I understood!</a
        >
      </span>
      <span
        class="intro-navi-menu intro-navi-menu-12 ProximaNovaRegular"
        v-if="index === 'breadcrumb'"
      >
        Breadcrumb
        <p class="intro-desc ProximaNovaRegular">
          Use the path or roadmap to navigate to previous screen
        </p>
        <a
          href="javascript:;"
          @click="navigate('finish')"
          class="intro-link ProximaNovaRegular"
          >I understood!</a
        >
      </span>

      <div id="myCarousel" class="carousel slide">
        <ol class="carousel-indicators">
          <li
            data-target="#myCarousel"
            ref="nav"
            data-slide-to="0"
            :class="{ active: allIndex.includes('nav') }"
          ></li>
          <li
            data-target="#myCarousel"
            ref="solution"
            data-slide-to="1"
            :class="{ active: allIndex.includes('solution') }"
          ></li>
          <li
            data-target="#myCarousel"
            ref="Data connection"
            data-slide-to="2"
            :class="{ active: allIndex.includes('Data connection') }"
          ></li>
          <li
            data-target="#myCarousel"
            ref="savedplane"
            data-slide-to="3"
            :class="{ active: allIndex.includes('savedplan') }"
          ></li>
          <li
            data-target="#myCarousel"
            ref="globalsearch"
            data-slide-to="4"
            :class="{ active: allIndex.includes('globalsearch') }"
          ></li>
          <li
            data-target="#myCarousel"
            ref="notifications"
            data-slide-to="5"
            :class="{ active: allIndex.includes('notifications') }"
          ></li>
          <li
            data-target="#myCarousel"
            ref="profile"
            data-slide-to="6"
            :class="{ active: allIndex.includes('profile') }"
          ></li>
          <li
            data-target="#myCarousel"
            ref="breadcrumb"
            data-slide-to="7"
            :class="{ active: allIndex.includes('breadcrumb') }"
          ></li>
        </ol>
      </div>
    </div>
  </div>
</template>

<script>
import Attribution from "@/components/Welcome.vue";

export default {
  name: "NewNav",
  props: ["changeIntro"],
  data() {
    return {
      index: "nav",
      allIndex: ["nav"],
    };
  },
  methods: {
    navigate(menu) {
      this.allIndex.push(menu);
      this.changeIntro(menu);
      this.index = menu;
      Attribution.methods.introBreadcrumb(menu);
    },
  },
};
</script>

<style scoped>
.z-index-header {
  z-index: 95;
}
.z-index-navbar {
  z-index: 97;
  top: 0;
}
.header-intro {
  background: rgba(0, 0, 0, 0.9);
  height: 100%;
  width: 100%;
  position: fixed;
}
.header-intro-arrow,
.header-intro-arrow-6,
.header-intro-arrow-7,
.header-intro-arrow-8,
.header-intro-arrow-9,
.header-intro-arrow-10,
.header-intro-arrow-11,
.header-intro-arrow-12 {
  position: absolute;
  z-index: 999;
}
.header-intro-arrow-6 {
  left: 270px;
  top: 160px;
}
.header-intro-arrow-7 {
  left: 280px;
  top: 123px;
}
.header-intro-arrow-8 {
  left: 395px;
  top: 120px;
}
.header-intro-arrow-9 {
  right: 50px;
  top: 120px;
}
.header-intro-arrow-10 {
  right: 280px;
  top: 120px;
}
.header-intro-arrow-11 {
  right: 220px;
  top: 120px;
}
.header-intro-arrow-12 {
  left: 290px;
  top: 155px;
}

.header-intro-main {
  text-align: center;
  margin-right: 300px;
}
.intro-navi-menu {
  width: 155px;
  height: 24px;
  font-size: 20px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #fff;
  display: inline-block;
}
.intro-navi-menu-5 {
  margin-top: 88px;
  margin-left: 90px;
  text-align: left;
}
.intro-navi-menu-6 {
  text-align: left !important;
  margin-left: 366px;
  margin-top: 169px;
  display: block;
}
.intro-navi-menu-7 {
  text-align: left !important;
  margin-left: 370px;
  margin-top: 138px;
  display: block;
}
.intro-navi-menu-8 {
  text-align: left !important;
  margin-left: 480px;
  margin-top: 134px;
  display: block;
}
.intro-navi-menu-9 {
  text-align: left !important;
  margin-top: 134px;
  display: block;
  float: right;
  margin-right: 125px;
}
.intro-navi-menu-10 {
  text-align: left !important;
  margin-top: 134px;
  display: block;
  width: 324px;
  height: 20px;
  float: right;
  margin-right: 165px;
}
.intro-navi-menu-11 {
  text-align: left !important;
  margin-right: 80px;
  margin-top: 134px;
  display: block;
  width: 324px;
  height: 20px;
  float: right;
}
.intro-navi-menu-12 {
  text-align: left !important;
  margin-top: 169px;
  display: block;
  margin-left: 380px;
  height: 20px;
}
.intro-navi-menu-9 .intro-desc {
  width: 222px;
}
.intro-desc {
  margin: 8px 0;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #fff;
  width: 392px;
}
.intro-link {
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #ffdd7c !important;
  display: inline-block;
  text-align: left;
  text-decoration: underline;
}
.carousel-content {
  position: absolute;
  top: 630px;
  left: -75px;
  width: 452px;
  height: 100px;
  font-family: ProximaNovaBold;
  font-size: 40px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.25;
  letter-spacing: normal;
  text-align: center;
  color: #222a37;
}

.carousel-inner {
  position: relative;
  height: 700px;
}

.carousel-indicators {
  position: fixed;
  width: auto !important;
  bottom: 30px;
}

/* .carousel-indicators li.active:first-child {
  background-color: #fff;
  width: 32px;
  height: 5px;
  object-fit: contain;
  border-radius: 4px;
  margin-left: 6px;
} */
.carousel-indicators li.active {
  background-color: #fff;
  width: 8px;
  height: 8px;
  object-fit: contain;
  border-radius: 6px;
  margin-left: 6px;
}

.carousel-indicators li {
  height: 8px;
  width: 8px;
  border: 2px solid #fff;
  margin-left: 4px;
  margin-right: 4px;
  display: inline-block;
  border-radius: 50%;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  width: 8px;
  height: 8px;
  margin: 0 8px;
  object-fit: contain;
  background: none;
}
</style>
