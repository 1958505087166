<template>
  <div class="round content-center" style="padding: 2px" 
  :style="{height: height+'px', width: width+'px'}">
    <img :src="require('@/assets/'+imageName+'.svg')" :alt="imageName" class="avatar2" />
  </div>
</template>

<script>
export default {
  props: ["imageName", "height", "width"],
};
</script>

<style scoped>
.avatar2 {
  vertical-align: middle;
  border-radius: 50%;
}
.round {
  border: 2.5px solid;
  border-radius: 50%;
}
</style>