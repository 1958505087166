<template>
  <div>
    <div>
      <label :for="labelId" class="profileHeading ProximaNovaRegular"
        >{{labelText}}</label
      >
    </div>
    <input :type="type" v-model="value" :id="labelId" @input="getInputValue" />
  </div>
</template>

<script>
export default {
  props: ["type", "labelId","labelText","userValue"],
  data() {
    return {
      value: "",
    };
  },
  methods: {
    getInputValue() {
      this.$emit("getInputValue", this.value);
    },
  },
  created(){
      this.value = this.userValue
  }
};
</script>

<style scoped>
input[type="text"],
input[type="password"],
input[type="number"] {
  border: 0;
  outline: 0;
  border-bottom: 1.5px solid #050505;
  color: #222a37;
  font-weight: bold;
  font-size: 16px;
  width: 100%;
}
</style>