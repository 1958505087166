import VueRouter from "vue-router";
import Vue from "vue";
import VueMeta from 'vue-meta'
Vue.use(VueMeta)

Vue.use(VueRouter);
export default new VueRouter({
    mode: "history",
    base: process.env.BASE_URL,
    routes: [{
            path: "/welcome",
            name: "Welcome",
            meta: {
                header: true,
                title:"Welcome to Datapoem"
            },
            component: () =>
                import ("../components/Welcome.vue"),
        },
        {
            path: "/",
            name: "Login",
            meta:{
                title:"Sign in to your Datapoem Account",
            },
            component: () =>
                import ("@/components/Login.vue"),
        },
        {
            path: "/digital-planning",
            name: "Digital Planning",
            meta: {
                header: true,
                navItem: "Solution",
                title: "Digital Planning"
            },
            component: () =>
                import("@/pages/DigitalPlanning.vue"),
        },
        {
            path: "/saved-plans/saved-digital-plans",
            name: "SavedDigitalPlans",
            meta: {
            header: true,
                navItem: "Solution",
                title: "Digital Planning"
            },
            component: () =>
                import("@/components/Table/DigitalPlanningCharts.vue"),
        },
        {
            path: "/digital-planning/performance",
            name: "Digital Planning Performance",
            meta: {
                header: true,
                navItem: "Solution",
                title: "Digital Planning"
            },
            component: () =>
                import("@/pages/PlanningAssets/Channels.vue"),
        },
        {
            path: "/digital-planning/performance/dashboard",
            name: "digital planning performance Dashboard",
            meta: {
                header: true,
                title: "Solutions Dashboard"
            },
            component: () =>
                import(
                    "@/pages/PlanningAssets/PerformanceDashboard.vue"
                ),
        },
        {
            path: "/digital-planning/performance/learning",
            name: "digital planning performance Learning",
            component: () =>
                import("../pages/PlanningAssets/Learning.vue"),
        },
        {
            path: "/solutions",
            name: "Solutions",
            meta: {
                header: true,
                navItem: "Solution",
            },
            component: () =>
                import ("@/pages/AIMO-AI/Attribution.vue"),
        },
        {
            path: "/solutions/platform",
            name: "PlatForm",
            meta: {
                header: true,
                navItem: "Solution",
            },
            component: () =>
                import ("@/pages/AIMO-AI/Platform.vue"),
        },
        {
            path: "/solutions/kpi-details",
            name: "KpiDetails",
            meta: {
                header: true,
                navItem: "Solution",
            },
            component: () =>
                import ("@/components/AIMO-AI/KpiDetails.vue"),
        },
        {
            path: "/solutions/results",
            name: "Results",
            meta: {
                header: true,
                navItem: "Solution",
            },
            component: () =>
                import ("@/components/AIMO-AI/Results.vue"),
        },
        {
            path: "/solutions/stats",
            name: "Stats",
            meta: {
                header: true,
                navItem: "Solution",
            },
            component: () =>
                import ("@/components/AIMO-AI/Stats.vue"),
        },
        {
            path: "/solutions/insights",
            name: "Insights",
            meta: {
                header: true,
                navItem: "Solution",
            },
            component: () =>
                import ("@/components/AIMO-AI/Insights.vue"),
        },
        {
            path: "/solutions/scenario-planning",
            name: "ScenarioPlanning",
            meta: {
                header: true,
                navItem: "Solution",
            },
            component: () =>
                import ("@/pages/Scenario/ScenarioPlanning.vue"),
        },
        {
            path: "/solutions/scenario-planning/planning-results",
            name: "PlanningResults",
            meta: {
                header: true,
                navItem: "Solution",
            },
            component: () =>
                import ("@/pages/Scenario/PlanningResults.vue"),
        },
        {
            path: "/solutions/future-planning/planning-results",
            name: "FuturePlanningResults",
            meta: {
                header: true,
                navItem: "Solution",
            },
            component: () =>
                import ("@/pages/AIMO-AI/PlanningResults.vue"),
        },
        {
            path: "/saved-plans",
            name: "SavedPlans",
            meta: {
                header: true,
                navItem:"Saved Plan",
                title:"Saved Plans"
            },
            component: () =>
                import ("@/pages/SavedPlans/SavedPlans.vue"),
        },
        {
            path: "/forgot-password",
            name: "ForgotPassword",
            meta:{
                title:"Reset your password"
            },
            component: () =>
                import ("../components/ForgotPassword.vue"),
        },
        {
            path: "/reset-password",
            name: "ResetPassword",
            meta:{
                title:"Reset Password"
            },
            component: () =>
                import ("../components/ResetPassword.vue"),
        },
        {
            path: "/process-done",
            name: "PasswordResetComplete",
            meta:{
                title:"Process Done"
            },
            component: () =>
                import ("../components/PasswordResetComplete.vue"),
        },
        {
            path: "/demo/usa/data",
            name: "USA-Data",
            meta: {
                header: true,
                navItem: "Demo",
            },
            component: () =>
                import ("../pages/DEMO/AIMO-USA/DataType.vue"),
        },
        {
            path: "/demo/usa/platform",
            name: "USA-Platform",
            meta: {
                header: true,
                navItem: "Demo",
                title:"USA-Demo - Platform"
            },
            component: () =>
                import ("../pages/DEMO/AIMO-USA/Platform.vue"),
        },
        {
            path: "/demo/usa/platform/models",
            name: "USA-Platform-Models",
            meta: {
                header: true,
                navItem: "Demo",
                title:"USA-Demo - Models"
            },
            component: () =>
                import ("../pages/DEMO/AIMO-USA/ModalsResults.vue"),
        },
        {
            path: "/demo/usa/platform/stats",
            name: "USA-Platform-Statics",
            meta: {
                header: true,
                navItem: "Demo",
                title:"USA-Demo - Actual vs Prediction"
            },
            component: () =>
                import ("../pages/DEMO/AIMO-USA/Stats.vue"),
        },
        {
            path: "/demo/usa/platform/insights",
            name: "USA-Platform-Insights",
            meta: {
                header: true,
                navItem: "Demo",
                title:"USA-Demo - Attribution, ROI Summary & Future Prediction"
            },
            component: () =>
                import ("../pages/DEMO/AIMO-USA/Insights.vue"),
        },
        {
            path: "/demo/usa/platform/future/planning-result",
            name: "USA-Platform-Planning Result",
            meta: {
                header: true,
                navItem: "Demo",
                title:"USA-Demo - Predicted Future"
            },
            component: () =>
                import ("../pages/DEMO/AIMO-USA/PlanningResults.vue"),
        },
        {
            path: "/demo/usa/platform/future/planning-result/scenario",
            name: "USA-Platform-Scenario",
            meta: {
                header: true,
                navItem: "Demo",
                title:"USA-Demo - Edit Scenario"
            },
            component: () =>
                import ("../pages/DEMO/AIMO-USA/NewScenarioPlanning.vue"),
        },
        {
            path: "/demo/digitalpoem/attribution",
            name: "DigitalPoem-Data",
            meta: {
                header: true,
                navItem: "Demo",
                title:"DigitalPoem-Demo - Platform"
            },
            component: () =>
                import ("../pages/DEMO/DigitalPoem/DataType.vue"),
        },
        {
            path: "/demo/digitalpoem/agile",
            name: "DigitalPoem-Agile",
            meta: {
                header: true,
                navItem: "Demo",
                title:"DigitalPoem-Demo - Agile Planning Result"
            },
            component: () =>
                import ("../pages/DEMO/DigitalPoem/AgilePlanningResults.vue"),
        },
        {
            path: "/demo/digitalpoem/platform",
            name: "DigitalPoem-Platform",
            meta: {
                header: true,
                navItem: "Demo",
                title:"DigitalPoem-Demo - Platform"
            },
            component: () =>
                import ("../pages/DEMO/DigitalPoem/Platform.vue"),
        },
        {
            path: "/demo/digitalpoem/model",
            name: "DigitalPoem-Model",
            meta: {
                header: true,
                navItem: "Demo",
                title:"DigitalPoem-Demo - Model Result"
            },
            component: () =>
                import ("../pages/DEMO/DigitalPoem/ModalsResults.vue"),
        },
        {
            path: "/demo/digitalpoem/stats",
            name: "DigitalPoem-Stats",
            meta: {
                header: true,
                navItem: "Demo",
                title:"DigitalPoem-Demo - Actual vs Prediction"
            },
            component: () =>
                import ("../pages/DEMO/DigitalPoem/Stats.vue"),
        },
        {
            path: "/demo/digitalpoem/insights",
            name: "DigitalPoem-Insights",
            meta: {
                header: true,
                navItem: "Demo",
                title:"DigitalPoem-Demo - Attribution"
            },
            component: () =>
                import ("../pages/DEMO/DigitalPoem/Insights.vue"),
        },
        {
            path: "/demo/digitalpoem/planning-result",
            name: "DigitalPoem-Result",
            meta: {
                header: true,
                navItem: "Demo",
                title:"DigitalPoem-Demo - Predicted Future"
            },
            component: () =>
                import ("../pages/DEMO/DigitalPoem/PlanningResults.vue"),
        },
        {
            path: "/demo/digitalpoem/planning-result/scenario-plan",
            name: "DigitalPoem-Scenario",
            meta: {
                header: true,
                navItem: "Demo",
                title:"DigitalPoem-Demo - Edit your Scenario"
            },
            component: () =>
                import ("../pages/DEMO/DigitalPoem/NewScenarioPlanning.vue"),
        },
        {
            path: "/upload-data/agile",
            name: "Agile",
            meta: {
                header: true,
                navItem: "Data connection",
                title:"Upload Data - Upload Static Data"
            },
            component: () =>
                import ("@/pages/Upload-data/agile.vue"),
        },
        {
            path: "/upload-data/kpi",
            name: "KPI",
            meta: {
                header: true,
                navItem: "Data connection",
            },
            component: () =>
                import ("@/pages/Upload-data/kpi.vue"),
        },
        {
            path: "/upload-data/model-result",
            name: "Model Result",
            meta: {
                header: true,
                navItem: "Data connection",
            },
            component: () =>
                import ("@/pages/Upload-data/modelResult.vue"),
        },
        {
            path: "/upload-data/data-stats",
            name: "Data Stats",
            meta: {
                header: true,
                navItem: "Data connection",
            },
            component: () =>
                import ("@/pages/Upload-data/dataStats.vue"),
        },
        {
            path: "/upload-data/upload-data-stats",
            name: "Upload Stats",
            meta: {
                header: true,
                navItem: "data-connection",
            },
            component: () =>
                import ("@/components/AIMO-AI/UploadDataStats.vue"),
        },
        {
            path: "/solutions/health-monitor",
            name: "HealthMonitor",
            meta: {
                header: true,
                navItem: "Solution",
                type: "dynamic",
                title:"Health Monitor - List of all plans"
            },
            component: () =>
                import ("@/pages/Health-Monitor/HealthMonitor.vue"),
        },
        {
            path: "/solutions/unified-campaign",
            name: "UnifiedSummerCampaign",
            meta: {
                header: true,
                navItem: "Solution",
                title:"Health Monitor - Overview Dashboard"
            },
            component: () =>
                import ("@/pages/Health-Monitor/UnifiedCampaign.vue"),
        },
        {
            path: "/solutions/unified-summer-campaign/:channel",
            name: "Channel Level Dashboard",
            meta: {
                header: true,
                navItem: "Solution",
                title:"Health Monitor - Channel Dashboard"
            },
            component: () =>
                import ("@/pages/Health-Monitor/ChannelDSV.vue"),
        },
        {
            path: "/solutions/unified-summer-campaign/:channel/:adtype",
            name: "Health Monitor Leadcards",
            meta: {
                header: true,
                navItem: "Solution",
                title:"Health Monitor - Channel Adtype Dashboard"
            },
            component: () =>
                import ("@/pages/Health-Monitor/ChannelWiseAdTypeDSV.vue"),
        },{
            path: "/solutions/overall-adtype-dsv/:overallAdType",
            name: "Overall AdTypeDSV",
            meta: {
                header: true,
                navItem: "Solution",
                title:"Health Monitor - Adtype Level Dashboard"
            },
            component: () =>
                import ("@/pages/Health-Monitor/AdTypeDSV.vue"),
        },
        {
            path: "/solutions/agile",
            name: "Solutions Agile",
            meta: {
                header: true,
                navItem: "Solution",
            },
            component: () =>
                import ("@/pages/Solutions/Agile.vue"),
        },
        {
            path: "/solutions/agile/platform",
            name: "Agile-PlatForm",
            meta: {
                header: true,
                navItem: "Solution",
            },
            component: () =>
                import ("@/pages/AIMO-AI/AgilePlatform.vue"),
        },
        {
            path: "/solutions/agile/future-planning/planning-results",
            name: "Agile FuturePlanningResults",
            meta: {
                header: true,
                navItem: "Solution",
            },
            component: () =>
                import ("@/pages/DEMO/AIMO/AgilePlanningResults.vue"),
        },

        {
            path: "/solutions/AIMO",
            name: "Solutions AIMO",
            meta: {
                header: true,
                navItem: "Solution",
            },
            component: () =>
                import ("@/pages/DEMO/AIMO/Index.vue"),
        },
        {
            path: "/solutions/AIMO/summary",
            name: "Solutions AIMO summary",
            meta: {
                header: true,
                navItem: "Solution",
            },
            component: () =>
                import ("@/pages/DEMO/AIMO/Summary.vue"),
        },
        {
            path: "/solutions/AIMO/results",
            name: "AIMO results",
            meta: {
                header: true,
                navItem: "Solution",
            },
            component: () =>
                import ("@/pages/DEMO/AIMO/Results.vue"),
        },
        {
            path: "/solutions/AIMO/stats",
            name: "AIMO stats",
            meta: {
                header: true,
                navItem: "Solution",
            },
            component: () =>
                import ("@/pages/DEMO/AIMO/Stats.vue"),
        },
        {
            path: "/solutions/AIMO/insights",
            name: "AIMO insights",
            meta: {
                header: true,
                navItem: "Solution",
            },
            component: () =>
                import ("@/pages/DEMO/AIMO/Insights.vue"),
        },
        {
            path: "/solutions/AIMO/future-planning/planning-results",
            name: "AIMO futurePlanningResults",
            meta: {
                header: true,
                navItem: "Solution",
            },
            component: () =>
                import ("@/pages/DEMO/AIMO/PlanningResults.vue"),
        },
        {
            path: "/solutions/AIMO/scenario-planning",
            name: "AIMO scenarioPlanning",
            meta: {
                header: true,
                navItem: "Solution",
            },
            component: () =>
                import ("@/pages/DEMO/AIMO/ScenarioPlanning.vue"),
        },
        {
            path: "/solutions/AIMO/scenario-planning/planning-results",
            name: "AIMO scenarioPlanningResults",
            meta: {
                header: true,
                navItem: "Solution",
            },
            component: () =>
                import ("@/pages/DEMO/AIMO/ScenarioPlanningResults.vue"),
        },
        {
            path: "/solutions/agile-attribution",
            name: "Solutions agile-attribution",
            meta: {
                header: true,
                navItem: "Solution",
            },
            component: () =>
                import ("@/pages/DEMO/AgileAttribution/Index.vue"),
        },
        {
            path: "/solutions/agile-attribution/data-source",
            name: "Solutions agile-attribution source",
            meta: {
                header: true,
                navItem: "Solution",
            },
            component: () =>
                import ("@/pages/DEMO/AgileAttribution/DataSource.vue"),
        },
        {
            path: "/solutions/agile-attribution/data-source/kpi",
            name: "Solutions agile-attribution kpi",
            meta: {
                header: true,
                navItem: "Solution",
            },
            component: () =>
                import ("@/pages/DEMO/AgileAttribution/KPI.vue"),
        },
        {
            path: "/solutions/agile-attribution/results",
            name: "Solutions agile-attribution results",
            meta: {
                header: true,
                navItem: "Solution",
            },
            component: () =>
                import ("@/pages/DEMO/AgileAttribution/Results.vue"),
        },
        {
            path: "/solutions/agile-attribution/stats",
            name: "Solutions agile-attribution stats",
            meta: {
                header: true,
                navItem: "Solution",
            },
            component: () =>
                import ("@/pages/DEMO/AgileAttribution/Stats.vue"),
        },
        {
            path: "/data-connection-platform/welcome",
            name: "Data connection",
            meta: {
                header: true,
                navItem: "Data connection",
            },
            component: () =>
                import ("@/pages/Data-Connection/PlatformWelcome.vue"),
        },
        {
            path: "/data-connection-platform/digital",
            name: "Omni Channel",
            meta: {
                header: true,
                navItem: "Data connection",
                title:"Data Connection - Create Connection & Datastreams"
            },
            component: () =>
                import ("@/pages/DataConnection/AddConnection.vue"),
        },
        {
            path: "/solutions/digital-poem/type",
            name: "AI-Digital Poem Data",
            meta: {
                header: true,
                navItem: "Solution",
            },
            component: () =>
                import ("@/pages/DigitalPoem-AI/DataType.vue"),
        },
        {
            path: "/solutions/digital-poem/data/check",
            name: "AI-Digital Poem DataCheck",
            meta: {
                header: true,
                navItem: "Solution",
            },
            component: () =>
                import ("@/pages/DigitalPoem-AI/DataSufficent.vue"),
        },
        {
            path: "/solutions/digital-poem/kpi",
            name: "AI-Digital Poem KPI",
            meta: {
                header: true,
                navItem: "Solution",
                title:"Digital Poem - Define your kpi to run model"
            },
            component: () =>
                import ("@/pages/DigitalPoem-AI/Platform.vue"),
        }, {
            path: "/solutions/digital-poem/model-results",
            name: "AI-Digital Poem Modal",
            meta: {
                header: true,
                navItem: "Solution",
                title:"Digital Poem - Model Result"
            },
            component: () =>
                import ("@/pages/DigitalPoem-AI/ModalResults.vue"),
        }, {
            path: "/solutions/digital-poem/stats",
            name: "AI-Digital Poem Stats",
            meta: {
                header: true,
                navItem: "Solution",
                title:"Digital Poem - Actual vs Prediction"
            },
            component: () =>
                import ("@/pages/DigitalPoem-AI/Stats.vue"),
        }, {
            path: "/solutions/digital-poem/attribution",
            name: "AI-Digital Poem Attribution",
            meta: {
                header: true,
                navItem: "Solution",
                title:"Digital Poem - Attribution & Predict Future"
            },
            component: () =>
                import ("@/pages/DigitalPoem-AI/Insights.vue"),
        }, {
            path: "/solutions/digital-poem/planning-result",
            name: "AI-Digital Poem Planning Result",
            meta: {
                header: true,
                navItem: "Solution",
                title:"Digital Poem - Predicted Future"
            },
            component: () =>
                import ("@/pages/DigitalPoem-AI/PlanningResults.vue"),
        }, {
            path: "/solutions/digital-poem/planning-result/scenario",
            name: "AI-Digital Poem Planning Result Scenario",
            meta: {
                header: true,
                navItem: "Solution",
                title:"Digital Poem - Edit Scenario Planning"
            },
            component: () =>
                import ("@/pages/DigitalPoem-AI/ScenarioPlanning.vue"),
        }, {
            path: "/solutions/data-summary",
            name: "Business At Glance",
            meta: {
                header: true,
                navItem: "Solution",
                title:"Business At Glance - Overview of your Data"
            },
            component: () =>
                import ("@/pages/BusinessAtGlance/BusinessAtGlance.vue"),
        }, {
            path: "/sign-up/registration",
            name: "Registration",
            meta: {
                header: false,
                navItem: "",
                title:"Create your Datapoem Account"
            },
            component: () =>
                import ("@/pages/Registration.vue"),
        }, {
            path: "/growth/platform",
            name: "Growth-Platform",
            meta: {
                header: true,
                navItem: "Demo",
            },
            component: () =>
                import ("@/pages/DEMO/Growth/Platform.vue"),
        }, {
            path: "/growth/platform/model",
            name: "Growth-Model",
            meta: {
                header: true,
                navItem: "Demo",
            },
            component: () =>
                import ("@/pages/DEMO/Growth/ModalsResults.vue"),
        }, {
            path: "/growth/platform/stats",
            name: "Growth-Stats",
            meta: {
                header: true,
                navItem: "Demo",
            },
            component: () =>
                import ("@/pages/DEMO/Growth/Stats.vue"),
        }, {
            path: "/growth/platform/insights",
            name: "Growth-Insights",
            meta: {
                header: true,
                navItem: "Demo",
            },
            component: () =>
                import ("@/pages/DEMO/Growth/Insights.vue"),
        },
        {
            path: "/solutions/in/platform",
            name: "AIMO-Platform",
            meta: {
                header: true,
                navItem: "Solution",
                title:"Platform"
            },
            component: () =>
                import ("@/pages/DEMO/AIMOSolutions/Platform.vue"),
        },
        //flow for uniliver 
        {
            path: "/solutions/ul/platform",
            name: "AIMO-Platform-Uniliver",
            meta: {
                header: true,
                navItem: "Solution",
                title: "Platform"
            },
            component: () =>
                import("@/pages/DEMO/AIMOSolutions2/Platform.vue"),
        },
        {
            path: "/solutions/in/platform/models",
            name: "AIMO-Platform-Models",
            meta: {
                header: true,
                navItem: "Solution",
                title:"Models"
            },
            component: () =>
            import ("@/pages/DEMO/AIMOSolutions/ModalsResults.vue"),
        },
        {
            path: "/solutions/ul/platform/models",
            name: "AIMO-Platform-Uniliver-Models",
            meta: {
                header: true,
                navItem: "Solution",
                title: "Models"
            },
            component: () =>
                import("@/pages/DEMO/AIMOSolutions2/ModalsResults.vue"),
        },
        {
            path: "/solutions/in/platform/stat",
            name: "AIMO-Platform-Stat",
            meta: {
                header: true,
                navItem: "Solution",
                title:"Actual Vs Prediction"
            },
            component: () =>
                import ("@/pages/DEMO/AIMOSolutions/Stats.vue"),
        },
        {
            path: "/solutions/in/platform/allstats",
            name: "AIMO-Platform-AllStat",
            meta: {
                header: true,
                navItem: "Solution",
                title:"All Model Stats"
            },
            component: () =>
                import ("@/pages/DEMO/AIMOSolutions/AllStats.vue"),
        },
        {
            path: "/solutions/ul/platform/allstats",
            name: "AIMO-UL-Platform-AllStat",
            meta: {
                header: true,
                navItem: "Solution",
                title:"All Model Stats"
            },
            component: () =>
                import ("@/pages/DEMO/AIMOSolutions2/AllStats.vue"),
        },
        {
            path: "/solutions/in/platform/attribution",
            name: "AIMO-Model-Chart",
            meta: {
                header: true,
                navItem: "Solution",
                title:"All Model Stack"
            },
            component: () =>
                import ("@/pages/DEMO/AIMOSolutions/AttributionAcid.vue"),
        },
        {
            path: "/solutions/ul/platform/attribution",
            name: "AIMO-Model-Uniliver-Chart",
            meta: {
                header: true,
                navItem: "Solution",
                title:"All Model Stack"
            },
            component: () =>
                import ("@/pages/DEMO/AIMOSolutions2/AttributionAcid.vue"),
        },
        {
            path: "/solutions/ul/platform/stat",
            name: "AIMO-Platform-Uniliver-Stat",
            meta: {
                header: true,
                navItem: "Solution",
                title: "Actual Vs Prediction"
            },
            component: () =>
                import("@/pages/DEMO/AIMOSolutions2/Stats.vue"),
        },
        {
            path: "/solutions/in/platform/acid-test",
            name: "AIMO-Platform-Acid Test",
            meta: {
                header: true,
                navItem: "Solution",
                title:"Acid Test"
            },
            component: () =>
                import ("@/pages/DEMO/AIMOSolutions/AcidTest.vue"),
        },
        {
            path: "/solutions/ul/platform/acid-test",
            name: "AIMO-Platform-Uniliver-Acid Test",
            meta: {
                header: true,
                navItem: "Solution",
                title: "Acid Test"
            },
            component: () =>
                import("@/pages/DEMO/AIMOSolutions2/AcidTest.vue"),
        },
        {
            path: "/solutions/in/platform/insights",
            name: "AIMO-Platform-Insights",
            meta: {
                header: true,
                navItem: "Solution",
                title:"Casuality Insights"
            },
            component: () =>
                import ("@/pages/DEMO/AIMOSolutions/Insights.vue"),
        },
        {
            path: "/solutions/ul/platform/insights",
            name: "AIMO-Platform-Uniliver-Insights",
            meta: {
                header: true,
                navItem: "Solution",
                title: "Casuality Insights"
            },
            component: () =>
                import("@/pages/DEMO/AIMOSolutions2/Insights.vue"),
        },
        {
            path: "/solutions/in/platform/future/planning-result",
            name: "AIMO-Platform-Planning-Result",
            meta: {
                header: true,
                navItem: "Solution",
                title:"Predicted Future"
            },
            component: () =>
                import ("@/pages/DEMO/AIMOSolutions/PlanningResults.vue"),
        },
        {
            path: "/solutions/ul/platform/future/planning-result",
            name: "AIMO-Platform-Uniliver-Planning-Result",
            meta: {
                header: true,
                navItem: "Solution",
                title: "Predicted Future"
            },
            component: () =>
                import("@/pages/DEMO/AIMOSolutions2/PlanningResults.vue"),
        },
        {
            path: "/solutions/in/platform/future/planning-result/scenario",
            name: "AIMO-Platform-Planning-Result Scenario",
            meta: {
                header: true,
                navItem: "Solution",
                title:"Edit Predicted Result"
            },
            component: () =>
                import ("@/pages/DEMO/AIMOSolutions/ScenarioPlanning.vue"),
        },
        {
            path: "/solutions/ul/platform/future/planning-result/scenario",
            name: "AIMO-Platform-Uniliver-Planning-Result Scenario",
            meta: {
                header: true,
                navItem: "Solution",
                title: "Edit Predicted Result"
            },
            component: () =>
                import("@/pages/DEMO/AIMOSolutions2/ScenarioPlanning.vue"),
        },
        {
            path: "/demo/in/platform",
            name: "AIMO-IN-Platform",
            meta: {
                header: true,
                navItem: "Demo",
                title:"Platform"
            },
            component: () =>
                import ("@/pages/DEMO/AIMO-IN/Platform.vue"),
        }, {
            path: "/demo/in/platform/models",
            name: "AIMO-IN-Platform-Models",
            meta: {
                header: true,
                navItem: "Demo",
                title:"Models"
            },
            component: () =>
                import ("@/pages/DEMO/AIMO-IN/ModalsResults.vue"),
        }, {
            path: "/demo/in/platform/stat",
            name: "AIMO-IN-Platform-Stat",
            meta: {
                header: true,
                navItem: "Demo",
                title:"Actual Vs Prediction"
            },
            component: () =>
                import ("@/pages/DEMO/AIMO-IN/Stats.vue"),
        },
        {
            path: "/demo/in/platform/insights",
            name: "AIMO-IN-Platform-Insights",
            meta: {
                header: true,
                navItem: "Demo",
                title:"Casuality Insights"
            },
            component: () =>
                import ("@/pages/DEMO/AIMO-IN/Insights.vue"),
        },
        {
            path: "/demo/in/platform/future/planning-result",
            name: "AIMO-IN-Platform-Planning-Result",
            meta: {
                header: true,
                navItem: "Demo",
                title:"Predicted Future"
            },
            component: () =>
                import ("@/pages/DEMO/AIMO-IN/PlanningResults.vue"),
        },
        {
            path: "/demo/in/platform/future/planning-result/scenario",
            name: "AIMO-IN-Platform-Planning-Result Scenario",
            meta: {
                header: true,
                navItem: "Demo",
                title:"Edit Predicted Result"
            },
            component: () =>
                import ("@/pages/DEMO/AIMO-IN/NewScenarioPlanning.vue"),
        },
        {
            path: "/settings/users",
            name: "UserList",
            meta: {
                header: true,
                navItem: "Settings",
                title:"User - Edit, Delete & Create New Users"
            },
            component: () =>
                import ("@/pages/UserManagement/UserList.vue"),
        },{
            path: "/settings/brands",
            name: "BrandList",
            meta: {
                header: true,
                navItem: "Settings",
                title:"Brand - All Brand & Create new brand"
            },
            component: () =>
                import ("@/pages/UserManagement/BrandList.vue"),
        },{
            path: "/connection/re-auth",
            name: "Reauthorize Connection",
            meta: {
                header: true,
                navItem: "Settings",
                title:"Re-Authorize - Reauthorize your connection"
            },
            component: () =>
                import ("@/pages/DataConnection/ReauthorizeConnection.vue"),
        },{
            path: "/saved/model/results",
            name: "Saved Model Result",
            meta: {
                header: true,
                navItem:"Saved Plan",
                title:"Saved Model- Overview Model"
            },
            component: () =>
                import ("@/pages/SavedPlans/ModelResultView/SavedModelResults.vue"),
        },{
            path: "/saved/model/results/stats",
            name: "Saved Model Stats",
            meta: {
                header: true,
                navItem:"Saved Plan",
                title:"Saved Model- Actual vs Prediction"
            },
            component: () =>
                import ("@/pages/SavedPlans/ModelResultView/SavedModelStats.vue"),
        },{
            path: "/saved/model/results/attribution",
            name: "Saved Model Attribution",
            meta: {
                header: true,
                navItem:"Saved Plan",
                title:"Saved Model- Attribution"
            },
            component: () =>
                import ("@/pages/SavedPlans/ModelResultView/SavedModelInsights.vue"),
        },{
            path: "/saved/model/results/optimized",
            name: "Saved Optimized Result",
            meta: {
                header: true,
                navItem:"Saved Plan",
                title:"Saved Plan- Saved Optimized Result"
            },
            component: () =>
                import ("@/pages/SavedPlans/OptimizedResultView/PlanningResults.vue"),
        },{
            path: "/settings/switch/user",
            name: "Switch User",
            meta: {
                header: true,
                navItem:"Settings",
                title:"Swith User"
            },
            component: () =>
                import ("@/pages/UserManagement/SwitchUserList.vue"),
        },{
            path: "/settings/cost-multiplier",
            name: "cost-multiplier",
            meta: {
                header: true,
                navItem:"Settings",
                title:"Cost Multiplier"
            },
            component: () =>
                import ("@/pages/CostMultiplier.vue"),
        },{
            path: "/new-slider",
            name: "Test",
            meta: {
                header: true,
                navItem:"Settings"
            },
            component: () =>
                import ("@/pages/DigitalPoem-AI/NewScenarioPlanning.vue"),
        }
    ], 
});