import { ApiHelper } from "./helper/ApiHelper";
const apiHelper = new ApiHelper();
export class LoginServices {
  constructor() {}
  // login(data) {
  //         const uri = `user-module/auth/login`
  //         return apiHelper.login(uri, data)
  //     }
  login(data) {
    const uri = `user-module/authenticate`;
    return apiHelper.login(uri, data);
  }
  getClientType(orgId) {
    const uri = `user-module/subscriber/getorg?id=${orgId}`;
    return apiHelper.get(uri);
  }
  updateUser(data) {
    const uri = `user-module/update_user`;
    return apiHelper.patch(uri, data);
  }
  getNotification(orgNumber, clientId) {
    const uri = `health-monitor-module/adverity/notification?orgNumber=${orgNumber}&clientId=${clientId}`;
    return apiHelper.get(uri);
  }
  registration(data) {
    const uri = `user-module/v1/user`;
    return apiHelper.post(uri, data);
  }
  verifyOtp(email, otp) {
    const uri = `user-module/v1/verify?email=${email}&otp=${otp}`;
    return apiHelper.get(uri);
  }
  resendOtp(email) {
    const uri = `user-module/v1/otp?email=${email}`;
    return apiHelper.get(uri);
  }
  resetPassword(email) {
    const uri = `user-module/v1/forget-otp?email=${email}`;
    return apiHelper.get(uri);
  }
  newPassword(data) {
    const uri = `user-module/forget-password`;
    return apiHelper.post(uri, data);
  }
  verifyEmail(email) {
    const uri = `user-module/v1/verify-email?email=${email}`;
    return apiHelper.get(uri);
  }
  getDomainList() {
    const uri = `health-monitor-module/v1/domain`;
    return apiHelper.get(uri);
  }
  getDocumentation(){
    const uri = `health-monitor-module/document`
    return apiHelper.get(uri)
  }
}
