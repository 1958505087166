<template>
  <div id="app">
    <w-header v-if="layout"></w-header>
    <router-view />
  </div>
</template>

<script>
import Header from "./widgets/Header.vue";
export default {
  name: "App",
  metaInfo() {
    return {
      meta: [
        {
          vmid: "description",
          name: "description",
          content: "Developed by a collective of AI experts, marketers, media planners, ... Data POEM is to marketers what Jarvis is to Iron Man or what Mr. Q is to James bond.",
        },
      ],
    };
  },
  components: {
    "w-header": Header,
    // HelloWorld
  },
  computed: {
    layout() {
      return this.$route.meta.header || false;
    },
  },
};
</script>

<style>
@import "./assets/style/style.css";
</style>
