<template>
  <div>
    <div class="page-wrapper chiller-theme" style="margin-top: 71px;">
      <main class="page-content">
        <div class="container-fluid">
          <ul
            id="breadcrumb"
            class="breadcrumb ProximaNovaRegular mx-4"
            :class="{ introBread: isIntroBreadcrumb }"
          >
            <li class="ProximaNovaBold">Welcome</li>
          </ul>
        </div>
        <div class="container">
          <div class="welcome" style="display: inline;">
            <img
              src="../assets/Icons/dashboard.png"
              alt="welcome"
              height="260px"
            />
            <div class="noconnection mt-3 text-center">
              <h4 style="font-weight: bold">Welcome {{ username }}</h4>
            </div>
          </div>
          <div></div>
        </div>
      </main>
    </div>
  </div>
</template>
<script>
import { LoginServices } from "../services/LoginServices";
const loginServices = new LoginServices();
export default {
  props: [],
  components: {},
  data() {
    return {
      padding: 0,
      isIntroBreadcrumb: false,
      menuMargin: 70,
      username: "Test",
      form: { email: "", password: "" },
    };
  },
  methods: {
    introBreadcrumb(menu) {
      var element = document.getElementById('breadcrumb');
      if(menu === 'breadcrumb') {
        element.classList.add('introBread');
      } else {
        element.classList.remove('introBread');
      }
    },
    getUserDetails() {
      loginServices
        .login(this.form)
        .then((res) => {
          if (res.accessToken) {
            sessionStorage.setItem("instance", "/b2c/in/");
            sessionStorage.setItem("Token", "Bearer " + res.accessToken);
            sessionStorage.setItem("userName", res.userName);
            sessionStorage.setItem("clientId", res.email);
            sessionStorage.setItem("subId", res.subId);
            sessionStorage.setItem("userId", res.userId);
            sessionStorage.setItem(
              "tools",
              JSON.stringify(res.subscriberToolType)
            );
            this.landingPage = res.aimoLanding;
            this.role = res.userRoles;
            this.allTools = res.subscriberToolType;
            this.userEmail = res.email;
            this.getClientType(res.subId);
          }
        })
        .catch((error) => {
          // this.invalid = error.toString();
          // this.pageLoader = false;
          // this.error = error.toString();
          console.log(error);
        });
    },
    getClientType(orgId) {
      loginServices
        .getClientType(orgId)
        .then((res) => {
          sessionStorage.setItem("clientType", res.clientType);
          sessionStorage.setItem("agencyId", res.agencyId);
          if (this.landingPage === "" || this.landingPage === null) {
            sessionStorage.setItem("aimoLanding", res.aimoLanding);
          } else {
            sessionStorage.setItem("aimoLanding", this.landingPage);
          }

          if (this.role == "ADMIN") {
            sessionStorage.setItem("Role", "ADMIN");
            // this.$router.push("/dsv");
          } else if (this.role == "USER") {
            sessionStorage.setItem("Role", "USER");
          } else if (this.role == "MASTER_ADMIN") {
            sessionStorage.setItem("Role", "MASTER_ADMIN");
          } else if (this.role === "SUPER_ADMIN") {
            sessionStorage.setItem("Role", "SUPER_ADMIN");
          }
          this.$router.push("/welcome");
          // this.pageLoader = false;
        })
        .catch((error) => {
          console.log(error);
          // this.pageLoader = false;
        });
    },
  },
  // beforeMount() {
  //   var location = window.location.href
  //     console.log(location +" location")
  // },
  // beforeCreate(){
  //   var location = window.location.href
  //     console.log(location +" location")
  // },
  created() {
    //   if (
    //     localStorage.getItem("googleOauth") &&
    //     !sessionStorage.getItem("Token")
    //   ) {
    //     var location = window.location.href
    //     var user = location.split("=")[1];
    //     console.log(user +" location")
    //     var user2 = user.split("%40")
    //     var data = user2[0]+'@'+user2[1]
    //     this.form.email = data;
    //     this.form.password = data;
    //     sessionStorage.clear();
    //     this.getUserDetails();
    //   }
    this.username = sessionStorage.getItem("userName");
  },
};
</script>
<style scoped>
.container {
  height: 100%;
}
.breadcrumb {
  background-color: transparent;
  margin-top: 32px;
}
.introBread {
  position: fixed;
  z-index: 1111;
  padding: 30px;
  background: #fff;
}
.welcome {
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-right: -50%;
  transform: translate(-50%, -50%);
}
</style>
