<template>
  <modal
    name="dataConnectionSolutionsModal"
    class="welcome--modal dataconnection-modal"
    @closed="hideWelcomePage"
    :clickToClose="false"
  >
    <div class="dataconnection-modal-body">
      <div class="w-100">
        <div class="row modal-padding">
          <div
            class="col-xs-12 col-sm-12 col-md-6 p-0 welcome mt-4 align-start"
          >
            <img src="../../assets/group-21.png" alt class="welcome--image" />
          </div>
          <div class="col-xs-12 col-sm-12 col-md-6 p-0 intro__content">
            <p class="Welcome-to-Data-Poem ProximaNovaBold">
              <b>{{ this.modalTitle }}</b>
            </p>
            <p class="headlines">
              Hey {{ userName }}, Please follow below steps.
            </p>
            <div class="row w-100 year-plans m-0 ProximaNovaRegular">
              <div>
                <h3>Steps</h3>
                <div style="font-size :medium">
                  <div class="d-flex mb-2">
                    <b>1. </b>
                    <p class="m-0">
                      Create Connection
                    </p>
                  </div>
                  <div class="d-flex mb-2">
                    <b>2. </b>
                    <p class="m-0">
                      Connection Summary
                    </p>
                  </div>
                  <div class="d-flex mb-2">
                    <b>3. </b>
                    <p class="m-0">
                      Create DataStream
                    </p>
                  </div>
                  <div class="d-flex mb-2">
                    <b>4. </b>
                    <p class="m-0">
                      DataStream Summary
                    </p>
                  </div>
                  <div class="d-flex mb-3">
                    <b>5. </b>
                    <p class="m-0">
                      Input Data Summary
                    </p>
                  </div>
                </div>
              </div>
              <!--<div class="col-12 w-100 mb-3 plan-list" v-for="(option, index) in optionList" :key="index">
                <div
                  class="years-card card"
                  :class="{ active: option.value === activePlan }"
                  @click="selectedPlan(option.value)"
                >
                  {{option.name}}
                </div>
              </div>-->
            </div>
            <div class="row col-12 mt-3 add-fetching-detail-btn">
              <w-button
                :buttonLoader="'normal'"
                :buttonText="'Get started'"
                @buttonClicked="letsStart"
              >
              </w-button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </modal>
</template>

<script>
import Button from "@/components/Profile/Button.vue";
import "@/assets/style/datepicker/index.css";
export default {
  name: "DataConnectionSolutionsModal",
  props: {
    optionList: {
      type: Array,
      default: () => [
        { name: "Agile", value: "agile" },
        { name: "Attribution", value: "attribution" },
        { name: "Health Monitor", value: "healthMonitor" },
        { name: "AIMO", value: "aimo" },
      ],
    },
  },
  data() {
    return {
      agile: false,
      attribution: false,
      healthMonitor: false,
      aimo: false,
      selected: "",
      isActive: 0,
      modalTitle: "",
      modalShow: false,
      activePlan: "",
      userName: "",
    };
  },
  components: {
    "w-button": Button,
  },
  computed: {},
  created() {
    this.userName = sessionStorage.getItem("userName");
  },
  methods: {
    open(title) {
      this.modalTitle = title;
      this.$modal.show("dataConnectionSolutionsModal");
    },
    hideWelcomePage() {
      this.$modal.hide("dataConnectionSolutionsModal");
    },
    letsStart() {
      this.$router.push("/data-connection-platform/digital");
      this.$emit("createSolution");
      this.$modal.hide("dataConnectionSolutionsModal");
    },
    selectedPlan(plan) {
      this.activePlan = plan;
    },
  },
};
</script>

<style lang="css" scoped>
.vm--modal {
  max-width: 50% !important;
}
.button {
  max-width: 200px !important;
}
::placeholder {
  color: #9aafd4;
  opacity: 1;
}
:-ms-input-placeholder {
  color: #9aafd4;
}
::-ms-input-placeholder {
  color: #9aafd4;
}
input#exampleInputEmail1 {
  box-shadow: none;
  border: 0;
}
.btn-effect-intro {
  width: 78% !important;
  margin-top: 0 !important;
}
.add-fetching-detail-btn {
  width: 90%;
  display: flex;
  align-items: flex-start;
}
p.headlines {
  font-family: "ProximaNovaRegular";
  font-size: 16px;
  font-weight: normal;
}
.years-card {
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: space-around;
  padding: 15px 10px;
  font-family: ProximaNovaRegular;
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  margin-right: 10px;
  float: left;
  cursor: pointer;
  background-color: #e9f0ff;
  border-color: #e9f0ff;
}
.years-card.card.active {
  background-color: #c8f0d1;
  border-color: #c8f0d1;
}
@media screen and (max-width: 768px) {
  p.headlines {
    font-family: "ProximaNovaRegular";
    font-size: 16px;
    font-weight: normal;
    width: 100%;
    text-align: center;
  }
  .col-12.w-100.mb-3.plan-list {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
  }
}
@media screen and (max-width: 900px) and (min-width: 768px) {
}
</style>
