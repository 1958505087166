<template>
  <div
    class="button update"
    style="border 1px solid;"
    :style="[float ==='right' ? {'float':'right'} : float ==='left' ? {'float':'left'} : 'float:none']"
    :class="{ disableButton: isDisable }"
    @click="updateUserDetails"
  >
    <span class="d-flex ProximaNovaSemiBold p-3 " style="justify-content: space-between">
      {{ buttonText }}

      <i
        class="fas fa-arrow-right ml-5 pl-4 d-flex"
        style="align-items: center"
        v-if="buttonLoader == 'normal'"
      ></i>
      <div class="loaderupdate ml-5" v-else-if="buttonLoader == 'loader'"></div
    ></span>
  </div>
</template>

<script>
export default {
  props:{
    buttonText:{
      type: String,
      required: true,
      default:"Click Me"
    },
    isDisable:{
      type: Boolean,
      required: false,
      default: false
    },
    buttonLoader:{
      type: String,
      required: false,
      default: "normal"
    },
    float: {
       type: String,
      required: false,
      default: "right"
    }
  },
  methods: {
    updateUserDetails() {
      this.$emit("buttonClicked");
    },
  },
};
</script>

<style scoped>
.disableButton {
  cursor: not-allowed !important;
  pointer-events: none;
  background-color: #dadee4 !important;
  color: #000 !important;
}
@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
.loaderupdate {
  border: 3px solid #f3f3f3;
  border-radius: 50%;
  border-top: 3px solid #3498db;
  width: 20px;
  height: 20px;
  -webkit-animation: spin 2s linear infinite; /* Safari */
  animation: spin 2s linear infinite;
  background-color: transparent;
  position: relative;
}
.button {
  padding: 0.75rem;
  cursor: pointer;
  text-decoration: underline;
  font-size: 16px;
  border-radius: 5px;
}
.button.update {
  background-color: #050505;
  color: #fff;
  text-decoration: none;
}

.button{
  overflow: hidden;
}
.button::before,
.button::after {
  background: #0078d7;
  content: '';
  position: absolute;
  z-index: -1;
}
.button::after {
  -webkit-transition: all 0.5s;
	-moz-transition: all 0.5s;
  -o-transition: all 0.5s;
	transition: all 0.5s;
}
.button {
  background: none;
  border-radius: 5px;
  color: #fff;
  display: block;
  font-weight: bold;
  position: relative;
}
.button::after {
  /*background-color: #f00;*/
  height: 100%;
  left: -55%;
  top: 0;
  transform: skew(50deg);
  transition-duration: 1s;
  transform-origin: top left;
  width: 0;
}
.button:hover:after {
  height: 100%;
  width: 155%;
}

</style>
