<template>
  <div @click="close">
    <span class="closeprofile close" title="Close">&times;</span>
  </div>
</template>

<script>
export default {
  methods: {
    close() {
      this.$emit("close");
    },
  },
};
</script>

<style scoped>
.closeprofile {
  color: #222a37 !important;
  font-size: 40px !important;
  top: 0px !important;
}

.close {
  position: absolute;
  right: 15px;
  top: -15px;
  color: #fff;
  font-size: 35px;
  font-weight: bold;
  margin-top: 10px;
}

.close:hover,
.close:focus {
  color: red;
  cursor: pointer;
}
.closeprofile:hover {
  color: #ff6d85 !important;
}
</style>