import Vue from "vue";
import App from "./App.vue";
import "./assets/Fonts/index.css";
import vmodal from "vue-js-modal";
import router from "./router/router";
import BootstrapVue from "bootstrap-vue";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue/dist/bootstrap-vue.css";
import vueButtonEffect from "vue-button-effect";
import * as ChartModuleMore from "highcharts/highcharts-more.js";
import HCSoldGauge from "highcharts/modules/solid-gauge";
import Highcharts from "highcharts";
import borderRadius from "highcharts-border-radius";
import HighchartsCustomEvents from "highcharts-custom-events";
import Drilldown from "highcharts/modules/drilldown.js";
import Stock from "highcharts/modules/stock.js";
import moment from "moment";
import VueCalendly from "vue-calendly";
import ToggleButton from "vue-js-toggle-button"
Vue.use(ToggleButton)
Vue.use(VueCalendly);
Vue.prototype.moment = moment;
import VueMeta from 'vue-meta'
Vue.use(VueMeta)

Drilldown(Highcharts);
HighchartsCustomEvents(Highcharts);
borderRadius(Highcharts);
ChartModuleMore(Highcharts);
HCSoldGauge(Highcharts);
Stock(Highcharts);

Vue.use(Highcharts);
Vue.use(vueButtonEffect);
Vue.use(vmodal);
Vue.use(BootstrapVue);
Vue.config.productionTip = false;

new Vue({
  render: (h) => h(App),
  router,
  watch: {
    $route: {
      immediate: true,
      handler(to) {
        document.title =
          `${process.env.VUE_APP_PAGE_TITLE} - ${to.meta.title}` ||
          `${process.env.VUE_APP_PAGE_TITLE}`;
      },
    },
  },

  beforeMount() {
    if (
      sessionStorage.getItem("Token") === null &&
      window.location.pathname !== "/" &&
      window.location.pathname !== "/forgot-password" &&
      window.location.pathname !== "/sign-up/registration" &&
      window.location.pathname !== "/reset-password" &&
      window.location.pathname !== "/process-done"
    ) {
      window.location.href = "/";
    }
  },
}).$mount("#app");
router.beforeEach((to, from, next) => {
  if (
    to.fullPath !== "/forgot-password" &&
    to.fullPath !== "/sign-up/registration" &&
    to.fullPath !== "/reset-password" &&
    to.fullPath !== "/process-done" &&
    to.fullPath !== "/" &&
    sessionStorage.getItem("Token") === null
  ) {
    window.location.href = "/";
  }
  document.title =
    `${process.env.VUE_APP_PAGE_TITLE} - ${to.meta.title}` ||
    `${process.env.VUE_APP_PAGE_TITLE}`;
  next();
});
